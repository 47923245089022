import { useEffect, useState } from "react";

const useLocation = () => {
  const [location, setLocation] = useState<any>(undefined);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch("/api/geolocation");
        const json = await res.json();
        setLocation(json);
        return json;
      } catch (error) {
        return;
      }
    })();
  }, []);
  return location;
};

export default useLocation;
