// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    dsn:
      SENTRY_DSN ||
      "https://28eb8d77bd734eb296af25b61ac34ed2@o876935.ingest.sentry.io/5827009",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Non-Error exception captured'
    ],
    tunnel: "/api/sentry_tunnel",
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
